
import React, { useContext, useState ,useEffect } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const EducationDetails = () => {
  const { fields, updateFields } = useContext(FormContext);
  const [jobData, setJobData] = useState([]);
  const [description, setDescription] = useState("");
  const [subcategories, setSubcategories] = useState();
  const [selectedSubcategory,setSelectedSubcategory] = useState("");
  const [educationDetailsFields, setEducationDetailsFields] = useState(
    fields.personalInfo?.educationDetails || [
      {
        gpa: "",
        university: "",
        specialization: "",
        major: "",
        degreeLevel: "",
        yearOfAdmission: "",
        yearOfGraduation: "",
        lastDegree: "",
        fieldOfStudy: "",
        jobRequest:selectedSubcategory,
        thesisOrInternship:"",
      },
    ]
  );
  const handleChange = (e) => {
    updateFields("personalInfo", { [e.target.name]: e.target.value });
  };
  const handleFieldChange = (e, index, fieldSetter, fieldState, sectionKey) => {
    const updatedFields = [...fieldState];
    updatedFields[index] = {
      ...updatedFields[index],
      [e.target.name]: e.target.value,
    };
  
    // Update local state
    fieldSetter(updatedFields);
  
    // Update context with the entire array
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };
  
 
  const handleAddField = (fieldSetter, fieldState, newField) => {
    const updatedFields = [...fieldState, newField];
  
    // Update local state
    fieldSetter(updatedFields);
  
    // Update context with the new field
    const sectionKey = fieldState === educationDetailsFields ? "educationDetailsFields" : "educationDetailsFields";
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };
  
 
  const handleRemoveField = (index, fieldSetter, fieldState, sectionKey) => {
    const updatedFields = fieldState.filter((_, idx) => idx !== index);
  
    // Update local state
    fieldSetter(updatedFields);
  
    // Update context with the removed field
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };
  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await fetch("https://api.asiamehr.com/api/v1/job");
        if (!response.ok) {
          throw new Error("Failed to fetch job data");
        }
        const data = await response.json();
        setJobData(data.data); // Assuming your API response contains an array of job data
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobData();
  }, []);
   // Log subcategories to ensure it's populated correctly
  const items = jobData?.map((submenu) => (
    <MenuItem key={submenu?._id} value={submenu?._id}>
      {submenu?.title} {/* Make sure 'title' exists */}
    </MenuItem>
  ));
  
  return (
    <>
      <Box
      sx={{
        direction: "rtl",
        padding: "20px",
        fontFamily: "IRANSans, Arial, sans-serif",
        // backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        اطلاعات وضعیت تحصیلی و تحصیلی
      </Typography>

      <form>
        <Grid container spacing={2}>
          {/* education Experience Section */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              سوابق تحصیلی
            </Typography>

            {educationDetailsFields.map((education, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} sm={6}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                      {" "}
           اخرین مدرک تحصیلی {" "}*
                    </InputLabel>
                  <FormControl fullWidth>
                  
                    <Select
                      name="lastDegree"
                      value={education.lastDegree}
                      onChange={(e) =>
                        handleFieldChange(
                          e,
                          index,
                          setEducationDetailsFields,
                          educationDetailsFields,
                          "educationDetails"
                        )
                      }
                      sx={{
                        height: '35px', // Adjust the height
                        fontSize: '0.875rem', // Smaller font size
                        padding: '0 14px', // Adjust the padding for smaller height
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            direction: "rtl", // Ensures menu alignment is right-to-left
                            textAlign: "right",
                          },
                        },
                      }}
                    >
                      <MenuItem value="ابتدایی"> ابتدایی</MenuItem>
                      <MenuItem value="راهنمایی">راهنمایی</MenuItem>
                      <MenuItem value="دبیرستان">دبیرستان</MenuItem>
                      <MenuItem value="لیسانس">لیسانس</MenuItem>
                      <MenuItem value="فوق لیسانس">فوق لیسانس</MenuItem>
                      <MenuItem value="دکترا">دکترا</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                <InputLabel sx={{ marginBottom: "8px" }}>رشته تحصیلی  </InputLabel>
                  <TextField
                    fullWidth
                    name="fieldOfStudy"
                    // label="رشته تحصیلی "
                    value={education.fieldOfStudy}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                      {" "}
                      درخواست شغل *{" "}
                    </InputLabel>
                  <FormControl fullWidth>
                  
                    <Select
                      name="jobRequest"
                      value={fields.personalInfo.jobRequest}
                      onChange={handleChange}
                      sx={{
                        height: '35px', // Adjust the height
                        fontSize: '0.875rem', // Smaller font size
                        padding: '0 14px', // Adjust the padding for smaller height
                      }}
                    >
                      {/* <MenuItem value="ابتدایی"> ابتدایی</MenuItem>
                      <MenuItem value="راهنمایی">راهنمایی</MenuItem>
                      <MenuItem value="دبیرستان">دبیرستان</MenuItem>
                      <MenuItem value="لیسانس">لیسانس</MenuItem>
                      <MenuItem value="فوق لیسانس">فوق لیسانس</MenuItem>
                      <MenuItem value="دکترا">دکترا</MenuItem> */}
                      {items}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}>مقطع تحصیلی   </InputLabel>
                  <TextField
                    fullWidth
                    name="degreeLevel"
                    // label="مقطع تحصیلی "
                    value={education.degreeLevel}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}>رشته   </InputLabel>
                  <TextField
                    fullWidth
                    name="major"
                    // label=" رشته  "
                    value={education.major}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}>دانشگاه </InputLabel>
                  <TextField
                    fullWidth
                    name="university"
                    // label=" دانشگاه  " 
                    value={education.university}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />

               
                </Grid>

                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}> گرایش  </InputLabel>

                  <TextField
                    fullWidth
                    name="specialization"
                    // label="گرایش " 
                    value={education.specialization}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}>سال ورود</InputLabel>
                  <TextField
                    fullWidth
                    name="yearOfAdmission"
                    type="number"
                    // type="date"
                    placeholder="لطفاً یک عدد وارد کنید" 
                    value={education.yearOfAdmission}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={1}>
                <InputLabel sx={{ marginBottom: "8px" }}>سال خروج</InputLabel>
                  <TextField
                    fullWidth
                    name="yearOfGraduation"
                    placeholder="لطفاً یک عدد وارد کنید" 
                    // label=" تاریخ پایان " 
                    type="number"
                    value={education.yearOfGraduation}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputLabel sx={{ marginBottom: "8px" }}> معدل </InputLabel>
                    <TextField
                      fullWidth
                      name="gpa"
                      // label=" معدل  "  
                      placeholder="لطفاً معدل را وارد کنید (بین ۰ تا ۲۰)" 
                      type="number"
                      value={education.gpa}
                      onChange={(e) =>
                        handleFieldChange(
                          e,
                          index,
                          setEducationDetailsFields,
                          educationDetailsFields,
                          "educationDetails"
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}> پایان‌نامه/کارآموزی </InputLabel>

                  <TextField
                    fullWidth
                    name="thesisOrInternship"
                    // label="گرایش " 
                    value={education.thesisOrInternship}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setEducationDetailsFields,
                        educationDetailsFields,
                        "educationDetails"
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {educationDetailsFields.length > 1 && (
                    <IconButton
                      onClick={() =>
                        handleRemoveField(
                          index,
                          setEducationDetailsFields,
                          educationDetailsFields,
                          "educationDetails"
                        )
                      }
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  {index === educationDetailsFields.length - 1 && (
                    <IconButton
                      onClick={() =>
                        handleAddField(
                          setEducationDetailsFields,
                          educationDetailsFields,
                          {
                            gpa: "",
                            university: "",
                            specialization: "",
                            major: "",
                            degreeLevel: "",
                            yearOfAdmission: "",
                            yearOfGraduation: "",
                          }
                        )
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </form>
    </Box>
    </>
  
  );
};

export default EducationDetails;
