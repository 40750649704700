import React, { useContext, useEffect, useRef, useState } from "react";
import { FormContext } from "../../Context/JobContext";
import PersonalInfoForm from "./PersonalInfo";
import HealthInfo from "./HealthInfo";
import EducationWorkInfo from "./EducationWork";
import EducationDetails from "./Education";
import {
  Button,
  Box,
  Typography,
  Snackbar,
  Alert,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Input, 
  FormControl, 
  FormLabel 
} from "@mui/material";
import Question from "./questions";
import Insurence from "./Insurence";
import RecruitmentBanner from "../Jobs/Banner";
import Nav from "../../Components/NavBar/Nav";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

const MainForm = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    isSubmitting,
    submitStatus,
    snack,
    setSnack,
    handleFileChange,
    setFields,
    handleProfilePictureChange,
    handleResumeOrDocumentsChange,
  } = useContext(FormContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSnackClose = () => {
    setSnack((prev) => ({ ...prev, open: false })); 
  };

  return (
    <>
      <Helmet>
        <title>{t('helmet.careers')} | آسیامهر فرداد</title>
        <meta name="description" content={t('helmet.careersDescription')} />
        <meta name="keywords" content="careers, jobs, asia mehr fardad, recruitment" />
      </Helmet>
  
      <Nav />
      <RecruitmentBanner />
      <Box sx={{ textAlign: "center", marginBottom: "40px", direction: "ltr" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            display: "inline-block",
            position: "relative",
            marginTop: "40px ",
          }}
        >
          فرم استخدام
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginTop: "20px",
            lineHeight: "2",
            color: "black",
            textAlign: "right",
            margin: "20px 30px",
          }}
        >
          فیلدهای ضروری را نشان می دهد "*"
        </Typography>
      </Box>
      <PersonalInfoForm />
      <HealthInfo />
      <EducationDetails />
      <EducationWorkInfo />
      <Insurence />
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 3 }}>
     
        <FormControl>
          <FormLabel htmlFor="profilePicture" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            عکس پروفایل
          </FormLabel>
          <Input
            id="profilePicture"
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={handleProfilePictureChange}
            sx={{
              display: 'block',
              marginTop: '0.5rem',
              padding: '10px',
              borderRadius: '4px',
              backgroundColor: '#f1f1f1',
            }}
          />
        </FormControl>

      
        <FormControl>
          <FormLabel htmlFor="resumeOrDocuments" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            فایل رزومه و مدارک
          </FormLabel>
          <Input
            id="resumeOrDocuments"
            type="file"
            accept=".zip,.pdf,.rar"
            onChange={handleResumeOrDocumentsChange}
            sx={{
              display: 'block',
              marginTop: '0.5rem',
              padding: '10px',
              borderRadius: '4px',
              backgroundColor: '#f1f1f1',
            }}
          />
        </FormControl>
      </Box>
      
      <Question />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
        sx={{
          width: { xs: "100px", sm: "100px" },
          fontSize: { xs: "0.9rem", sm: "1rem" },
          padding: { xs: "10px", sm: "12px 20px" },
          borderRadius: 2,
          alignItems: "right",
          textAlign: "right",
          background: "rgb(189, 155, 25)",
          margin: "40px 40px ",
        }}
      >
        {isSubmitting ? "در حال ارسال..." : "ارسال فرم"}
      </Button>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>

      <Footer />
    
    </>
  );
};

export default MainForm;
