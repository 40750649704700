import React from "react";
import { Box, Typography } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HiringSteps = () => {
  return (
    <>
      <Box
      sx={{
        backgroundColor: "#1E392A",
        padding: "40px 20px",
        textAlign: "center",
        borderRadius: "8px",
      }}
    >
     
      <Typography
        variant="h4"
        sx={{
          color: "#ffffff",
          fontWeight: "bold",
          marginBottom: "40px",
        }}
      >
        مراحل استخدامی آسیامهر
      </Typography>

      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap", 
          gap: "20px", 
          direction:'ltr'
        }}
      >
      
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            width: "150px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              marginBottom: "15px",
              borderRadius: "8px",
            }}
          >
            <WorkIcon sx={{ fontSize: "40px", color: "#1E392A" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#ffffff",
              fontWeight: "500",
              fontSize:"15px"
            }}
          >
            بررسی فرصت های شغلی
          </Typography>
          {/* Arrow */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "-40px", // Adjust arrow position
              transform: "translateY(-50%)",
              display: { xs: "none", sm: "block" }, // Hide arrow on small screens
            }}
          >
            <svg
              width="40"
              height="10"
              viewBox="0 0 40 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 5H35M35 5L30 0M35 5L30 10"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </Box>
        </Box>

        {/* Step 2 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            width: "150px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              marginBottom: "15px",
              borderRadius: "8px",
            }}
          >
            <DescriptionIcon sx={{ fontSize: "40px", color: "#1E392A" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#ffffff",
              fontWeight: "500",
            }}
          >
            تکمیل فرم استخدام
          </Typography>
       
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "-40px", 
              transform: "translateY(-50%)",
              display: { xs: "none", sm: "block" },
            }}
          >
            <svg
              width="40"
              height="10"
              viewBox="0 0 40 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 5H35M35 5L30 0M35 5L30 10"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </Box>
        </Box>

       
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            width: "150px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              marginBottom: "15px",
              borderRadius: "8px",
            }}
          >
            <PersonPinIcon sx={{ fontSize: "40px", color: "#1E392A" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#ffffff",
              fontWeight: "500",
            }}
          >
            مصاحبه حضوری
          </Typography>
          {/* Arrow */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "-40px", 
              transform: "translateY(-50%)",
              display: { xs: "none", sm: "block" }, 
            }}
          >
            <svg
              width="40"
              height="10"
              viewBox="0 0 40 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 5H35M35 5L30 0M35 5L30 10"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </Box>
        </Box>

      
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "150px",
          }}
        >
          <Box
            sx={{
              width: "80px",
              height: "80px",
              backgroundColor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              marginBottom: "15px",
              borderRadius: "8px",
            }}
          >
            <CheckCircleIcon sx={{ fontSize: "40px", color: "#1E392A" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{
              color: "#ffffff",
              fontWeight: "500",
            }}
          >
            استخدام
          </Typography>
        </Box>
      </Box>
    </Box>
    </>
  
  );
};

export default HiringSteps;
