import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PublicIcon from '@mui/icons-material/Public';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { Helmet } from 'react-helmet';

function MainCart() {
  const { t } = useTranslation();

  return (
    <>
    <Helmet>
          <title>{t('companyResponsibility.title')} </title>
          <meta name="description" content={t('companyResponsibility.title')} />
          <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
        </Helmet>
      <Box
      sx={{
        backgroundColor: '#F5F5F5',
        color: 'white',
        padding: { xs: '2rem', md: '6rem' },
        textAlign: 'center',
        width:"100%",
       
      }}
    >
      {/* Title Section */}
      <Typography
        variant="h3"
        sx={{
          fontWeight: 700,
          color: '#c1a122',
          marginBottom: '1.5rem',
        
        }}
      >
        {t('companyResponsibility.title')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: ' #03452f',
          marginBottom: '4rem',
          fontSize: '1.2rem',
          lineHeight: '1.8',
         
        }}
      >
        {t('companyResponsibility.main-text')}
      </Typography>

      {/* Icons Section */}
      <Grid container spacing={4} justifyContent="center">
        {[
          { icon: <ManageHistoryIcon />, label: 'manage' },
          { icon: <PublicIcon />, label: 'export' },
          { icon: <GroupsIcon />, label: 'professional_team' },
          { icon: <AnalyticsOutlinedIcon />, label: 'analysis' },
          { icon: <EmojiObjectsOutlinedIcon />, label: 'ideation' },
          { icon: <EngineeringOutlinedIcon />, label: 'engineering_team' },
        ].map((item, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '12px',
                padding: '2rem',
                boxShadow: 5,
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                '&:hover': {
                  backgroundColor: '#c1a122',
                  transform: 'scale(1.05)',
                  boxShadow: '0 10px 30px rgba(255, 215, 0, 0.3)',
                },
              }}
            >
              <IconButton
                sx={{
                  color: 'black',
                  fontSize: '70px',
                  transition: 'color 0.3s',
                  '&:hover': {
                    color: 'white',
                  },
                }}
              >
                {item.icon}
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  color: 'black',
                  marginTop: '1rem',
              
                  fontWeight: 600,
                }}
              >
                {t(item.label)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
    </>
  
  );
}

export default MainCart;