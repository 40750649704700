import React from 'react'
import HiringSteps from './steps'
import JobCards from './JobCarts'
import EmploymentGuide from './Expln'
import RecruitmentBanner from './Banner'
import Nav from '../../Components/NavBar/Nav'
import Footer from '../../Components/Footer/Footer'



export default function Main() {
  return (
    <div>
      <Nav/>
    <RecruitmentBanner/>
      <EmploymentGuide/>
      <HiringSteps/>
      <JobCards/>
      <Footer/>
    </div>
  )
}
