import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";

const EmploymentGuide = () => {
  return (
    <>  <Box
    sx={{
      fontFamily: "Vazirmatn, sans-serif",
      backgroundColor: "#f8f8f8",
      padding: "20px",
      width:'100%',
      height:"auto",
    }}
    dir="rtl"
  >
<Box sx={{ textAlign: "center", marginBottom: "40px", direction: "rtl" }}>
<Typography
  variant="h4"
  sx={{
    fontWeight: "bold",
    display: "inline-block",
    position: "relative",
  //   "&:before, &:after": {
  //     content: '""',
  //     position: "absolute",
  //     height: "3px",
  //     backgroundColor: "#FFD700", // Better gold color
  //     width: "60px",
  //     top: "50%",
  //   },
  //   "&:before": {
  //     right: "100%",
  //     marginRight: "15px",
  //   },
  //   "&:after": {
  //     left: "100%",
  //     marginLeft: "15px",
  //   },
  }}
>
  راهنمای تکمیل فرم استخدام آسیامهر
</Typography>

{/* Additional Text */}
<Typography
  variant="body1"
  sx={{
    marginTop: "20px",
    lineHeight: "2",
    color: "black",
    textAlign: "justify",
    margin:"20px 30px"
    // Align text for better readability
  }}
>
  ضمن تشکر از حسن توجه شما به سبب انتخاب این مجموعه برای همکاری، امیدواریم که در آینده نزدیک شاهد حضور شما کنار خود و در خانواده بزرگ آسیامهر باشیم.
  <br />
  بدین منظور و قبل از شروع به تکمیل فرم استخدام مجموعه، لازم است که اسکن مدارک ذیل در دسترس بوده تا در محل‌های مورد نظر در سیستم ثبت گردند.
  <br />
  در تمامی صفحات، ورود گزینه‌های ستاره‌دار الزامی می‌باشد.
</Typography>
</Box>


    {/* Title Section */}
    <Box sx={{ textAlign: "center", marginBottom: "40px", direction: "ltr" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          display: "inline-block",
          position: "relative",
          // "&:before, &:after": {
          //   content: '""',
          //   position: "absolute",
          //   height: "3px",
          //   backgroundColor: "#FFD700", // Better gold color
          //   width: "60px",
          //   top: "50%",
          // },
          // "&:before": {
          //   right: "100%",
          //   marginRight: "15px",
          // },
          // "&:after": {
          //   left: "100%",
          //   marginLeft: "15px",
          // },
        }}
      >
        اسکن مدارک مورد نیاز
      </Typography>
    </Box>

    {/* Icon Section */}

    {/* Checklist Section */}
    <Box
      sx={{
     
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "40px",
        display: "flex",
        flexWrap: "wrap", // Enable wrapping for smaller screens
        justifyContent: "center", // Center content horizontally
        alignItems: "center", // Center content vertically
        direction: "rtl", // Right-to-left alignment for text
        textAlign: "center",
      }}
    >
      {/* Icon Centered */}

      {/* Checklist Below */}
      <Box
        sx={{
          flex: "1 1 100%", // Take full width on smaller screens
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start", // Align the checklist to the right
          maxWidth: "800px", // Limit width for better readability
          gap: "12px", // Spacing between items
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            color: "black",
            textAlign: "center", // Center text
          }}
        >
          اسکن مدارک مورد نیاز
        </Typography>
        <List>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            عکس پرسنلی
          </ListItem>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            کارت ملی
          </ListItem>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            کارت پایان خدمت سربازی (برای آقایان)
          </ListItem>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            فایل رزومه (اختیاری)
          </ListItem>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            گواهینامه دوره های مهارتی گذرانده شده مختلف (اختیاری)
          </ListItem>
          <ListItem
            sx={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <CheckCircleIcon sx={{ color: "#D4AF37", marginLeft: "8px" }} />
            مدرک ایثارگری یا جانبازی (اختیاری)
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          // Take full width on smaller screens
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <AssignmentIcon
          sx={{
            fontSize: { xs: "200px", sm: "300px", md: "300px" }, // Responsive font sizes
            color: "#D4AF37",
          }}
        />
      </Box>
    </Box>

    {/* Conditions Section */}
    <Box
      sx={{
      
        padding: "20px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      {" "}
      <Box
        sx={{ textAlign: "center", marginBottom: "40px", direction: "ltr" }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            display: "inline-block",
            position: "relative",
          //   "&:before, &:after": {
          //     content: '""',
          //     position: "absolute",
          //     height: "3px",
          //     backgroundColor: "#FFD700", // Better gold color
          //     width: "60px",
          //     top: "50%",
          //   },
          //   "&:before": {
          //     right: "100%",
          //     marginRight: "15px",
          //   },
          //   "&:after": {
          //     left: "100%",
          //     marginLeft: "15px",
          //   },
          }}
        >
          راهنمای تکمیل فرم استخدام آسیامهر
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ lineHeight: 2, color: "#333" }}>
        <br />
        <br />
        داشتن تابعیت ایران و یا اتباع خارجی دارای شرایط اشتغال مجاز در ایران
        طبق ماده ۱۲۰ قانون کار
        <br />
        اعتقاد به دین مبین اسلام یا یکی از ادیان شناخته شده در قانون اساسی
        جمهوری اسلامی ایران
        <br />
        انجام خدمت دوره ضرورت یا معافیت قانونی دائم برای آقایان
        <br />
        عدم اعتیاد به مواد مخدر و دخانیات
        <br />
        نداشتن سابقه محکومیت جزایی موثر
        <br />
        داشتن سلامت جسمانی و روانی و توانایی برای انجام کاری که استخدام می
        شوند
        <br />
        دارا بودن شرایط احراز شغل متناسب با جداول نیازهای استخدامی برای شغل
        مورد تقاضا
        <br />
        نداشتن منع قانونی برای استخدام و تعهد خدمتی به دیگر سازمان ها
        <br />
        داشتن حداقل مدرک دیپلم و مدارک مرتبط با هر ردیف شعلی در جدول نیازهای
        استخدامی
        <br />
        ثبت نام و درخواست همکاری با  کارخانه آسیامهر در حال حاضر به دو
        صورت الکترونیکی و حضوری صورت می پذیرد. اما تکمیل فرم الکترونیکی در این
        سایت میتواند در تسریع فرآیند جذب و بررسی موثر واقع شود.
        <br />
        ثبت نام در این سایت و شرکت در مصاحبه ، هیچگونه تعهد استخدامی برای 
        کارخانه آسیامهر ایجاد نکرده و متقاضیان در صورت نیاز سازمان و بر اساس
        اولویت های داخلی ، مورد گزینش قرار خواهند گرفت .
        <br />
        کلیه متقاضیان می بایست نسبت به ثبت اطلاعات و بارگذاری عکس و کلیه مدارک
        خواسته شده در فرم ثبت نام ( که خلاصه آن در ذیل آمده است) و ارسال موفق
        آن اطمینان حاصل نموده و کد رهگیری مربوطه را جهت قرارگرفتن در مسیر
        مراحل استخدام و ویرایش اطلاعات نزد خود نگهداری نمایند.
        <br />
        زمان مصاحبه بعد از ارزیابی های اولیه و متناسب با مشاغل مورد نیاز 
        کارخانه آسیامهر ، از طریق بخش رهیگیری همین سایت و یا بصورت پیامکی به
        متقاضیان اعلام می گردد.
        <br />
        با توجه به اطلاع رسانی های مختلف در طی فرایند بر روی تلفن همراه وارد
        شده ، لطفا از صحت شماره همراه ثبت شده در طی فرایند ثبت نام اطمینان
        حاصل نمایید.
        <br />
       
       کارخانه آسیامهر مختار است به فراخور نیاز و اولویت های داخلی و
        همچنین نیاز واحد های مختلف ، افراد را در سطح شرکت های زیر مجموعه خود
        جابجا نماید.
        <br />
        مسئولیت ناشی از عدم رعایت دقیق ضوابط و شرایط اعلام شده در این راهنما
        به عهده متقاضی خواهد بود. لذا چنانچه در هر یک از مراحل ؛ حتی پس از
        شروع کار؛ مشخص گردد داوطلبی اطلاعات خلاف واقع اعلام نموده است، موضوع
        استخدامی وی منتفی و در صورت صدور قرارداد، قرارداد مذکور لغو می گردد.
      </Typography>
    </Box>
  </Box></>
  
  );
};

export default EmploymentGuide;
