import Slider from '../../Components/Slider/Slider'
import React from 'react'
import Cart from '../Cart/cart'
import Main from '../Main/Main'

import MainCart from '../Main/MainCart'
import MainGallery from '../Main/MainGallery'
import Nav from '../../Components/NavBar/Nav'
import Carrousel from '../Main/Carrousel'
import NewsTabs from '../News/newsTabs'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet'


import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  return (
    <div>
       <Helmet>
          <title>{t('companyResponsibility.title')} </title>
          <meta name="description" content={t('companyResponsibility.title')} />
          <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
        </Helmet>
      <Nav />
      <Slider />
      <Main />
      <MainCart />
      <MainGallery />
      <Carrousel />
      {/* <NewsTabs /> */}
      <Footer/>
    </div>
  )
}

export default Home
