import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Person2TwoTone from "@mui/icons-material/Person2TwoTone";
import TranslateButton from "../../Translate/TranslateButton";



const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "fa" || i18n.language === "ar";
  const isMobile = useMediaQuery("(max-width: 900px)");
  const handleLoginClick = () => {
    window.location.href = "https://asiamehr.com/login";
  };
  const toggleMenu = (state) => {
    setIsOpen(state);
  };

  return (
    <nav className="navbar">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        p={2}
        bgcolor="rgba(255, 255, 255, 0.8)"
        position="fixed"
        top={0}
        left={0}
        zIndex={1000}
        boxShadow="0px 4px 10px rgba(0,0,0,0.1)"
        backdropFilter="blur(10px)"
        sx={{
          flexDirection: isRTL ? "row-reverse" : "row",
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <Link
          to="/"
          className="navbar-logo"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "#333",
          }}
        >
          <img
            src="assest/real.png"
            alt="اسیامهر"
            style={{
              height: "60px",
              width: "auto",
              margin: isRTL ? "0 10px 0 0" : "0 0 0 10px", // Adjust margin based on RTL
            }}
          />
        </Link>

        {isMobile ? (
          <Box display="flex" alignItems="center">
            <TranslateButton/>
            <IconButton
              onClick={() => toggleMenu(!isOpen)}
              sx={{ color: "#fff", ml: 1, background: "#03452f" }}
            >
              {isOpen ? (
                <CloseIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        ) : (
          <Box display="flex" gap={3} alignItems="center">
            {["home", "about", "gallery", "contact", "hire"].map((item) => (
              <Link
                key={item}
                to={`/${item === "home" ? "" : item}`}
                className="nav-link"
                style={{
                  color: "#333",
                  textDecoration: "none",
                  paddingBottom: "4px",
                  transition: "border-bottom 0.3s ease",
                  borderBottom: "2px solid transparent",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.borderBottom = "2px solid #c1a122")
                }
                onMouseLeave={(e) =>
                  (e.target.style.borderBottom = "2px solid transparent")
                }
              >
                {t(`navbar.${item}`)}
              </Link>
            ))}

          
            <TranslateButton />
          </Box>
        )}
      </Box>

      {/* Mobile Drawer */}
      <Drawer
        anchor={isRTL ? "left" : "right"}
        open={isOpen}
        onClose={() => toggleMenu(false)}
      >
        <Box
          width="250px"
          role="presentation"
          bgcolor="rgba(255, 255, 255, 0.9)"
          height="100vh"
          sx={{
            textAlign: isRTL ? "right" : "left",
            direction: isRTL ? "rtl" : "ltr",
            backdropFilter: "blur(15px)",
          }}
        >
          <List>
            {["home", "about", "gallery", "contact", "hire"].map((item) => (
              <ListItem
                key={item}
                button
                component={Link}
                to={`/${item === "home" ? "" : item}`}
                onClick={() => toggleMenu(false)}
                sx={{
                  transition: "border-bottom 0.3s ease",
                  borderBottom: "2px solid transparent",
                  "&:hover": { borderBottom: "2px solid #c1a122" },
                }}
              >
                <ListItemText
                  primary={t(`navbar.${item}`)}
                  sx={{ color: "#333" }}
                />
              </ListItem>
            ))}

          
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default Nav;
