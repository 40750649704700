import React, { useContext } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";

const Question = () => {
  const { fields, updateFields } = useContext(FormContext);

  const handleChange = (e) => {
    updateFields("personalInfo", { [e.target.name]: e.target.value });
  };

  return (
    <>
      <Box
      sx={{
        direction: "rtl",
        padding: "20px",
        fontFamily: "IRANSans, Arial, sans-serif",
        // backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        سوالات تکمیلی
      </Typography>
      <form>
        <Grid container spacing={2}>
          <InputLabel sx={{ marginBottom: "8px" }}>
            چگونه با مجموعه آشنا شدید؟ *
          </InputLabel>
          <FormControl fullWidth sx={{ marginBottom: "16px" }}>
            <Select
              name="howDidYouGetToKnow"
              value={fields.personalInfo.howDidYouGetToKnow}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
              variant="outlined"
            >
              <MenuItem value="" disabled>
                انتخاب کنید
              </MenuItem>
              <MenuItem value="وب‌سایت شرکت">وب‌سایت شرکت</MenuItem>
              <MenuItem value="تبلیغات">تبلیغات</MenuItem>
              <MenuItem value="دوستان و آشنایان">دوستان و آشنایان</MenuItem>
              <MenuItem value="شبکه‌های اجتماعی">شبکه‌های اجتماعی</MenuItem>
              <MenuItem value="سایر">سایر</MenuItem>
            </Select>
          </FormControl>
          <InputLabel sx={{ marginBottom: "8px" }}>
            آیا دوستان و آشنایان شما در این مجموعه مشغول به کار هستند؟*
          </InputLabel>
          <FormControl fullWidth sx={{ marginBottom: "16px" }}>
            <Select
              name="doFriendsWorkHere" 
              value={fields.personalInfo.doFriendsWorkHere}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
              variant="outlined"
            >
              <MenuItem value="" disabled>
                انتخاب کنید
              </MenuItem>
              <MenuItem value="بله">بله</MenuItem>
              <MenuItem value="خیر">خیر</MenuItem>
            </Select>
          </FormControl>
          <InputLabel sx={{ marginBottom: "8px" }}>
            آیا تاکنون با گروه اسیامهر همکاری داشته‌اید؟*
          </InputLabel>
          <FormControl fullWidth sx={{ marginBottom: "16px" }}>
            <Select
              name="haveWorkedBefore"
              value={fields.personalInfo.haveWorkedBefore}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}    MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
              
              variant="outlined"
            >
              <MenuItem value="" disabled>
                انتخاب کنید
              </MenuItem>
              <MenuItem value="بله">بله</MenuItem>
              <MenuItem value="خیر">خیر</MenuItem>
            </Select>
          </FormControl>
          {/* City */}
          <Grid item xs={12} sm={12}>
            <InputLabel sx={{ marginBottom: "8px" }}>
              {" "}
              توضیحات تکمیلی
            </InputLabel>
            <TextField
              fullWidth
              name="additionalNotes"
              value={fields.personalInfo.additionalNotes}
              onChange={handleChange}
              multiline
              column={12}
              rows={8} // تعداد سطرها برای ارتفاع بیشتر
              variant="outlined"
              sx={{
                marginBottom: "16px",
                minHeight: "200px", // حداقل ارتفاع
                fontSize: "16px", // سایز فونت\
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Box></>
  
  );
};

export default Question;
