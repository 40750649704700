import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slider.css";
import { useTranslation } from "react-i18next";
import { Scrollbar } from "swiper/modules";
import i18next from "i18next";

function Slider() {
  const [sliderData, setSliderData] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await fetch("https://api.asiamehr.com/api/v1/slider");
        if (!response.ok) throw new Error("Failed to fetch slider data");

        const data = await response.json();
        setSliderData(data?.data?.slider);

        i18next.addResourceBundle(i18n.language, "translation", {}, true);
        const newTranslations = {};
        data?.data?.slider?.forEach((slide) => {
          slide.mytext.forEach(({ lang, title, description }) => {
            if (!newTranslations[lang]) newTranslations[lang] = {};
            newTranslations[lang][`slider.${slide._id}.title`] = title;
            newTranslations[lang][`slider.${slide._id}.description`] = description;
          });
        });
        Object.keys(newTranslations).forEach((lang) => {
          i18next.addResourceBundle(lang, "translation", newTranslations[lang], true);
        });
        await i18next.reloadResources();
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, [i18n.language]);

  return (
    <div className="slider">
      <Swiper scrollbar={{ hide: true }} modules={[Scrollbar]} className="mySwiper">
        {sliderData?.map((slide, index) => (
          <SwiperSlide key={slide._id} className={`slide-position-${index % 3}`}>
            <img src={`https://api.asiamehr.com${slide.image}`} alt="" />
            <div className={`slider-text position-${index % 3}`}>
              <h1>{t(`slider.${slide._id}.title`, { defaultValue: slide.title })}</h1>
              <p>{t(`slider.${slide._id}.description`, slide.description)}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;