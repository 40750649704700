import React, { useContext } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
const PersonalInfoForm = () => {
  const { fields, updateFields } = useContext(FormContext);

  const handleChange = (e) => {
    updateFields("personalInfo", { [e.target.name]: e.target.value });
  };

  return (
    <> <Box
    sx={{
      direction: "rtl",
      padding: "20px",
      fontFamily: "IRANSans, Arial, sans-serif",
      // backgroundColor: "#f9f9f9",
    }}
  >
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        textAlign: "center",
        marginBottom: "20px",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      اطلاعات شخصی
    </Typography>
    <form>
      <Grid container spacing={2}>
        {/* First Name */}
        <Grid item xs={12} sm={3}>
          <InputLabel sx={{ marginBottom: "8px" }}>نام *</InputLabel>
          <TextField
            fullWidth
            name="fullName"
            value={fields.personalInfo.fullName}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Last Name */}
        <Grid item xs={12} sm={2}>
          <InputLabel sx={{ marginBottom: "8px" }}>نام خانوادگی *</InputLabel>
          <TextField
            fullWidth
            name="lastName"
            value={fields.personalInfo.lastName}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>نام پدر *</InputLabel>
          <TextField
            fullWidth
            name="fatherName"
            value={fields.personalInfo.fatherName}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputLabel sx={{ marginBottom: "8px" }}>تاریخ تولد *</InputLabel>
          <TextField
            fullWidth
            // type="date"
            name="dateOfBirth"
             placeholder="برای مثال 1380/2/4"
            value={fields.personalInfo.dateOfBirth}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
      
        <Grid item xs={12} sm={2}>
          <InputLabel sx={{ marginBottom: "8px" }}>محل تولد *</InputLabel>
          <FormControl fullWidth>
            <Select
              name="birthPlace"
              value={fields.personalInfo.birthPlace}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
            >
              <MenuItem value="" disabled>انتخاب کنید</MenuItem>
              <MenuItem value="آذربایجان شرقی">آذربایجان شرقی</MenuItem>
              <MenuItem value="آذربایجان غربی">آذربایجان غربی</MenuItem>
              <MenuItem value="البرز">البرز</MenuItem>
              <MenuItem value="اردبیل">اردبیل</MenuItem>
              <MenuItem value="اصفهان">اصفهان</MenuItem>
              <MenuItem value="ایلام">ایلام</MenuItem>
              <MenuItem value="بوشهر">بوشهر</MenuItem>
              <MenuItem value="تهران">تهران</MenuItem>
              <MenuItem value="چهارمحال و بختیاری">
                چهارمحال و بختیاری
              </MenuItem>
              <MenuItem value="خراسان جنوبی">خراسان جنوبی</MenuItem>
              <MenuItem value="خراسان رضوی">خراسان رضوی</MenuItem>
              <MenuItem value="خراسان شمالی">خراسان شمالی</MenuItem>
              <MenuItem value="خوزستان">خوزستان</MenuItem>
              <MenuItem value="زنجان">زنجان</MenuItem>
              <MenuItem value="سمنان">سمنان</MenuItem>
              <MenuItem value="سیستان و بلوچستان">سیستان و بلوچستان</MenuItem>
              <MenuItem value="فارس">فارس</MenuItem>
              <MenuItem value="قزوین">قزوین</MenuItem>
              <MenuItem value="قم">قم</MenuItem>
              <MenuItem value="کردستان">کردستان</MenuItem>
              <MenuItem value="کرمان">کرمان</MenuItem>
              <MenuItem value="کرمانشاه">کرمانشاه</MenuItem>
              <MenuItem value="کهگیلویه و بویراحمد">
                کهگیلویه و بویراحمد
              </MenuItem>
              <MenuItem value="گلستان">گلستان</MenuItem>
              <MenuItem value="گیلان">گیلان</MenuItem>
              <MenuItem value="لرستان">لرستان</MenuItem>
              <MenuItem value="مازندران">مازندران</MenuItem>
              <MenuItem value="مرکزی">مرکزی</MenuItem>
              <MenuItem value="هرمزگان">هرمزگان</MenuItem>
              <MenuItem value="همدان">همدان</MenuItem>
              <MenuItem value="یزد">یزد</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* City */}
        <Grid item xs={12} sm={2}>
          <InputLabel sx={{ marginBottom: "8px" }}>شهر *</InputLabel>
          <TextField
            fullWidth
            name="city"
            value={fields.personalInfo.city}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>کد ملی</InputLabel>
          <TextField
            fullWidth
            name="nationalId"
            value={fields.personalInfo.nationalId}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Gender */}
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>جنسیت *</InputLabel>
          <FormControl fullWidth>
            <Select
              name="gender"
              value={fields.personalInfo.gender}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
            >
              <MenuItem value="مرد">مرد</MenuItem>
              <MenuItem value="زن">زن</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Marital Status */}
        <Grid item xs={12} sm={8}>
          <InputLabel sx={{ marginBottom: "8px" }}>وضعیت تأهل*</InputLabel>
          <FormControl fullWidth>
            <Select
              name="maritalStatus"
              value={fields.personalInfo.maritalStatus}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
            >
              <MenuItem value="متاهل">متاهل</MenuItem>
              <MenuItem value="مجرد">مجرد</MenuItem>
              <MenuItem value="مطلقه">مطلقه</MenuItem>
              <MenuItem value="بیوه">بیوه</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Mobile */}
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>تلفن همراه *</InputLabel>
          <TextField
            fullWidth
            name="phone"
            value={fields.personalInfo.phone}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Emergency Phone */}
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>تلفن ضروری *</InputLabel>
          <TextField
            fullWidth
            name="emergencyPhone"
            value={fields.personalInfo.emergencyPhone}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={4}>
          <InputLabel sx={{ marginBottom: "8px" }}>ایمیل *</InputLabel>
          <TextField
            fullWidth
            type="email"
            name="email"
            value={fields.personalInfo.email}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Address */}
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: "8px" }}>
            آدرس محل سکونت *
          </InputLabel>
          <TextField
            fullWidth
            multiline
            name="address"
            value={fields.personalInfo.address}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={{ marginBottom: "8px" }}>کد پستی </InputLabel>
          <TextField
            fullWidth
            name="postalCode"
            value={fields.personalInfo.postalCode}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
      </Grid>

 
    </form>
  </Box></>
   
  );
};

export default PersonalInfoForm;
