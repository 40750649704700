// FormContext.js
import React, { createContext, useState } from "react";
import { Snackbar } from "@mui/material";
export const FormContext = createContext();
export const FormProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [subcategories, setSubcategories] = useState();
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "success", // success, error, info, warning
  });

  const [fields, setFields] = useState({
    profilePicture: null,
    resumeOrDocuments: null,
    personalInfo: {
      fullName: "",
      lastName: "",
      fatherName: "",
      dateOfBirth: "",
      birthPlace: "",
      nationalId: "",
      phone: "",
      gender: "",
      maritalStatus: "",
      additionalNotes: "",
      haveWorkedBefore: "",
      emergencyPhone: "",
      email: "",
      address: "",
      postalCode: "",
      city: "",
      healthStatus: "",
      surgeryHistory: "",
      disabilityStatus: "",
      doFriendsWorkHere: "", // اضافه شد
      howDidYouGetToKnow: "", // اضافه شد
      insuranceStatus: "", // اضافه شد
      educationDetails: [
        {
          degreeLevel: "", // مقطع تحصیلی
          major: "", // رشته تحصیلی
          specialization: "", // گرایش
          university: "", // دانشگاه / موسسه
          yearOfAdmission: "", // سال ورود
          yearOfGraduation: "", // سال خروج
          gpa: "", // معدل
          thesisOrInternship: "", // پایان‌نامه یا کارآموزی
          lastDegree: "",
          fieldOfStudy: "",
         
        },
      ],
      jobRequest: "",
      workExperience: [
        {
          companyName: "", // شرکت / سازمان / نهاد
          jobTitle: "", // عنوان شغل
          duration: "", // مدت همکاری
          reasonForLeaving: "", // علت قطع همکاری
          lastSalary: "", // آخرین حقوق دریافتی
        },
      ],
      cours: [
        {
          courseTitle: "",
          duration: "",
          location: "",
          date: "",
          organizer: "",
          skillLevel: "",
        },
      ],

    },

  });

  // State برای مدیریت وضعیت ارسال
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const handleFileChange = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];

    if (file) {
      setFields((prev) => ({
        ...prev,
        personalInfo: {
          ...prev.personalInfo,
          [name]: file,
        },
      }));
    }
  };


  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setFields({ ...fields, profilePicture: file });
  };
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setUpdateFields({ ...updateFields, profilePic: file });
  // };

  // const handleSignatureChange = (e) => { 
  //   const file = e.target.files[0];
  //   setUpdateFields({ ...updateFields, signature: file });
  // };

  const handleResumeOrDocumentsChange = (e) => {
    const file = e.target.files[0];
    setFields({ ...fields, resumeOrDocuments: file });
  };







  const updateFields = (section, updatedData) => {
    setFields((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        ...updatedData,
      },
    }));
  };

  const handleSubmit = async (resumeEl, imageEl) => {

    setIsSubmitting(true);
    setSubmitStatus("");
    const requiredFields = [
      { field: "fullName", label: "نام کامل" },
      { field: "lastName", label: "نام خانوادگی" },
      { field: "fatherName", label: "نام پدر" },
      { field: "dateOfBirth", label: "تاریخ تولد" },
      { field: "birthPlace", label: "محل تولد" },
      { field: "phone", label: "شماره تلفن" },
      { field: "gender", label: "جنسیت" },
      { field: "maritalStatus", label: "وضعیت تاهل" },
      { field: "emergencyPhone", label: "شماره تماس اضطراری" },
      { field: "address", label: "آدرس" },
      { field: "healthStatus", label: "وضعیت سلامت" },
      { field: "surgeryHistory", label: "سابقه جراحی" },
      { field: "insuranceStatus", label: "وضعیت بیمه" },
      { field: "doFriendsWorkHere", label: "آیا دوستان و آشنایان شما در این مجموعه مشغول به کار هستند؟" },
      { field: "haveWorkedBefore", label: "آیا تاکنون با گروه اسیامهر همکاری داشته‌اید" },
      { field: "howDidYouGetToKnow", label: "چگونه با مجموعه آشنا شدید؟ " },
      { field: "city", label: "شهر" },
      { field: "jobRequest", label: "درخواست شغلی" },
    ];

    // Check if any required field is empty
    for (const { field, label } of requiredFields) {
      if (!fields.personalInfo[field]) {
        setSnack({
          open: true,
          message: `لطفاً ${label} را وارد کنید.`,
          severity: "error",
        });
        setIsSubmitting(false);
        return; // Stop submission if a required field is empty
      }
    }
    try {


      const formData = new FormData();

     

      formData.append("fullName", fields.personalInfo.fullName);
      formData.append("lastName", fields.personalInfo.lastName);
      formData.append("fatherName", fields.personalInfo.fatherName);
      formData.append("dateOfBirth", fields.personalInfo.dateOfBirth);
      formData.append("birthPlace", fields.personalInfo.birthPlace);
      formData.append("nationalId", fields.personalInfo.nationalId);
      formData.append("phone", fields.personalInfo.phone);
      formData.append("gender", fields.personalInfo.gender);
      formData.append("maritalStatus", fields.personalInfo.maritalStatus);
      formData.append("additionalNotes", fields.personalInfo.additionalNotes);
      formData.append("haveWorkedBefore", fields.personalInfo.haveWorkedBefore);
      formData.append("emergencyPhone", fields.personalInfo.emergencyPhone);
      formData.append("email", fields.personalInfo.email);
      formData.append("address", fields.personalInfo.address);
      formData.append("postalCode", fields.personalInfo.postalCode);
      formData.append("city", fields.personalInfo.city);
      formData.append("healthStatus", fields.personalInfo.healthStatus);
      formData.append("surgeryHistory", fields.personalInfo.surgeryHistory);
      formData.append("disabilityStatus", fields.personalInfo.disabilityStatus);
      formData.append("previousCollaboration", fields.personalInfo.previousCollaboration);
      formData.append("doFriendsWorkHere", fields.personalInfo.doFriendsWorkHere);
      formData.append("howDidYouGetToKnow", fields.personalInfo.howDidYouGetToKnow);
      formData.append("insuranceStatus", fields.personalInfo.insuranceStatus);
      formData.append("jobRequest", fields.personalInfo.jobRequest);
      fields.personalInfo.workExperience.forEach((work, index) => {
        Object.entries(work).forEach(([key, value]) => {
          formData.append(`workExperience[${index}][${key}]`, value);
        });
      });

      fields.personalInfo.educationDetails.forEach((edu, index) => {
        Object.entries(edu).forEach(([key, value]) => {
          formData.append(`educationDetails[${index}][${key}]`, value);
        });
      });

      fields.personalInfo.cours.forEach((course, index) => {
        Object.entries(course).forEach(([key, value]) => {
          formData.append(`cours[${index}][${key}]`, value);
        });
      });
      
      if (fields.profilePicture) {
        formData.append("profilePicture", fields.profilePicture);
      }
      if (fields.resumeOrDocuments) {
        formData.append("resumeOrDocuments", fields.resumeOrDocuments);
      }

    
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      const response = await fetch(
        "https://api.asiamehr.com/api/v1/jobapp/apply",
        {
          method: "POST",
          body: formData,

        }
      );

      if (!response.ok) {
        const result = await response.json();


        throw new Error("ارسال فرم با شکست مواجه شد.");
        setSubmitStatus("ارسال فرم با شکست مواجه شد.");
        const data = await response.json();

        setSnack({
          open: true,
          message: data.message,
          severity: "success",
        });
      }

      const result = await response.json();

      setSnack({
        open: true,
        message: "فرم با موفقیت ارسال شد!",
        severity: "success",
      });
      setSubmitStatus("فرم با موفقیت ارسال شد!");


      // Reset fields after successful submission
      setFields((prevState) => ({
        ...prevState,
        profilePicture: null,
        resumeOrDocuments: null,
        personalInfo: {
          fullName: "",
          lastName: "",
          fatherName: "",
          dateOfBirth: "",
          birthPlace: "",
          nationalId: "",
          gender: "",
          maritalStatus: "",
          emergencyPhone: "",
          email: "",
          address: "",
          postalCode: "",
          city: "",
          phone: "",
          healthStatus: "",
          surgeryHistory: "",
          disabilityStatus: "",
          additionalNotes: "",
          previousCollaboration: "",
          haveWorkedBefore: "",
          doFriendsWorkHere: "",
          howDidYouGetToKnow: "",
          insuranceStatus: "",
          educationDetails: [
            {
              degreeLevel: "",
              major: "",
              specialization: "",
              university: "",
              yearOfAdmission: "",
              yearOfGraduation: "",
              gpa: "",
              thesisOrInternship: "",
             
            },
          ],
          jobRequest: "",
          workExperience: [
            {
              companyName: "",
              jobTitle: "",
              duration: "",
              reasonForLeaving: "",
              lastSalary: "",
            },
          ],
          cours: [
            {
              courseTitle: "",
              duration: "",
              location: "",
              date: "",
              organizer: "",
              skillLevel: "",
            },
          ],

        },



      }));



    } catch (error) {
      setSubmitStatus("ارسال فرم با شکست مواجه شد.");
      setSnack({
        open: true,
        message: " مشکلی در ارسال فرم پیش آمده است. لطفاً دوباره تلاش کنید.",
        severity: "error",
      });
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormContext.Provider
      value={{
        fields,
        setFields,
        updateFields,
        handleSubmit,
        isSubmitting,
        submitStatus,
        snack,
        setSnack, // Add this
        handleFileChange,
        handleProfilePictureChange,
        handleResumeOrDocumentsChange,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
