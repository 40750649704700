import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import Nav from '../../Components/NavBar/Nav';
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet";


function About() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "fa" || i18n.language === "ar";

  return (
    <>
      <Helmet>
        <title>{t('helmet.aboutUs')} | آسیامهر فرداد</title>
        <meta name="description" content={t('helmet.aboutUsDescription')} />
        <meta name="keywords" content="about us, company, asia mehr fardad, technology" />
      </Helmet>

      <Typography
        variant="h1"
        sx={{
          fontSize: '3rem',
          fontWeight: 700,
          marginBottom: '20px',
          textAlign: 'center', 
          lineHeight: '1.5',     
          color: '#333',   
          textAlign:"center",
          marginTop:"150px"
        }}
      >
        {t('helmet.aboutUs')}
      </Typography>
    
      <Box sx={{ padding: "60px 20px", width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <Nav />
        
        <Box
          sx={{
            maxWidth: "1300px",
            margin: "auto",
            padding: { xs: "50px 20px", md: "80px 30px" },
            textAlign: isRTL ? "right" : "left",
            direction: isRTL ? "rtl" : "ltr",
            width: "100%",
          }}
        >
          <Grid container spacing={6} alignItems="center">
          
            <Grid item xs={12} md={6}>
              <Paper
                elevation={5}
                sx={{
                  borderRadius: "16px",
                  overflow: "hidden",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  minHeight: "400px",
                }}
              >
                <Box
                  component="img"
                  src='/assest/logg.png'
                  alt="About Us Image"
                  sx={{
                    width: "100%",
                    height: "600px",
                    objectFit: "contain",
                    borderRadius: "16px",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    left: isRTL ? "auto" : 16,
                    right: isRTL ? 16 : "auto",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "12px",
                    padding: "20px 30px",
                    width: "90%",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    {t("aboutpage.description2")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  color: "#1A1A1A",
                  mb: 3,
                  letterSpacing: "1px",
                }}
              >
                {t("aboutpage.headingText")}
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: "#555", fontSize: "20px", mb: 2 }}
              >
                {t("aboutpage.subHeadingText")}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#444", fontSize: "17px", lineHeight: 1.7, mb: 4 }}
              >
                {t("aboutpage.description")}
              </Typography>
              <Button
                component={Link}
                to="/project"
                variant="contained"
                sx={{
                  backgroundColor: "#8B5E3C",
                  color: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                  padding: "14px 28px",
                  textTransform: "none",
                  fontWeight: "bold",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    backgroundColor: "#6B3F23",
                    transform: "scale(1.05)",
                  },
                }}
              >
                {t("buttonn.explore")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default About;
