import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Nav from '../../Components/NavBar/Nav';
import Footer from '../../Components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

const Project = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/project');
        const data = await response.json();
        if (response.ok) {
          setProjects(data.data.projectCarts);
          const newTranslations = {};
          data?.data?.projectCarts?.forEach((project) => {
            project.mytext.forEach(({ lang, title, description }) => {
              if (!newTranslations[lang]) newTranslations[lang] = {};
              newTranslations[lang][`projectCarts.${project._id}.title`] = title;
              newTranslations[lang][`projectCarts.${project._id}.description`] = description;
            });
          });
          Object.keys(newTranslations).forEach((lang) => {
            i18next.addResourceBundle(lang, "translation", newTranslations[lang], true);
          });
          await i18next.reloadResources();
        } else {
          console.error('Failed to fetch project data', data);
        }
      } catch (error) {
        console.error('Error fetching project data', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('helmet.projects')} | آسیامهر فرداد</title>
        <meta name="description" content={t('helmet.projectsDescription')} />
        <meta name="keywords" content="projects, asia mehr fardad, technology, development" />
      </Helmet>

      <Nav />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: "100%",
          height: 'auto',
          textAlign: 'center',
          padding: '0 16px', // افزودن padding برای جلوگیری از overflow
          overflowX: 'hidden', // برای جلوگیری از بیرون زدگی افقی
        }}
      >
        <Box sx={{ backgroundColor: '#306352', padding: '20px', textAlign: 'center', marginTop: "100px", width: '100%' }}>
          <Typography variant="h3" sx={{ color: 'goldenrod' }}>
            {t('project.title')}
          </Typography>
        </Box>

        <Grid container spacing={4} sx={{ marginTop: '30px', justifyContent: 'center', width: '100%' }}>
          {projects?.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project._id}>
              <Card sx={{
                width: '100%', height: 400, display: 'flex', flexDirection: 'column',
                boxShadow: 3, alignItems: 'center', justifyContent: 'center',
                borderRadius: '8px', overflow: 'hidden' , marginBottom:"60px"
              }}>
                <CardMedia
                  component="img"
                  image={`https://api.asiamehr.com${project.image}`}
                  alt={project.title}
                  sx={{ height: 300, objectFit: 'cover' }}
                />
                <CardContent sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {t(`projectCarts.${project._id}.title`, { defaultValue: project.title })}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {t(`projectCarts.${project._id}.description`, { defaultValue: project.description })}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Footer />
    </>
  );
};

export default Project;
