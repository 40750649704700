import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Access translation functions

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        background: "linear-gradient(135deg, #f8f6ed, #695a89)",
        color: "#fff",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "6rem", color: "#ff6f61", mb: 2 }}>
        {t("error404.title")}
      </Typography>
      <Typography variant="h6" sx={{ mb: 3 }}>
        {t("error404.message")}
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#ff6f61",
          color: "#fff",
          padding: "0.8rem 2rem",
          fontSize: "1rem",
          borderRadius: "20px",
          "&:hover": { backgroundColor: "#e65a50" },
        }}
        onClick={() => navigate("/")}
      >
        {t("error404.backToHome")}
      </Button>
    </Box>
  );
};

export default Error404;
