import React, { useContext, useState } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";

const Insurence = () => {
  const { fields, updateFields, handleFileChange } = useContext(FormContext);

  const handleChange = (e) => {
    updateFields("personalInfo", { [e.target.name]: e.target.value });
  };
 

  return (
    <>  
     <Box
      sx={{
        direction: "rtl",
        padding: "20px",
        fontFamily: "IRANSans, Arial, sans-serif",
        // backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      ></Typography>
      <form>
        <Grid container spacing={2}>
       
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ marginBottom: "8px" }}>
              وضعیت سابقه بیمه *
            </InputLabel>
            <FormControl fullWidth sx={{ marginBottom: "16px" }}>
              <Select
                name="insuranceStatus"
                value={fields.personalInfo.insuranceStatus}
                onChange={handleChange}
                required
                MenuProps={{
                  PaperProps: {
                    sx: {
                      direction: "rtl", // Ensures menu alignment is right-to-left
                      textAlign: "right",
                    },
                  },
                }}
              >
                <MenuItem value="">انتخاب کنید</MenuItem>
                <MenuItem value="بدون سابقه">بدون سابقه</MenuItem>
                <MenuItem value="با سابقه">با سابقه</MenuItem>
                <MenuItem value="دیگر">دیگر</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Box>
    </>
   
  );
};

export default Insurence;
