import React, { useState, useEffect } from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function MainGallery() {
  const { t } = useTranslation();
  const [cartData, setCartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Fetch cart data on component mount
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/cart');
        if (!response.ok) {
          throw new Error('Failed to fetch cart data');
        }
        const data = await response.json();
        setCartData(data.data.carts);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    fetchCartData();
  }, []);

  // Update scroll position on scroll event
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('companyResponsibility.title')}</title>
        <meta name="description" content={t('companyResponsibility.title')} />
        <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
      </Helmet>

      <Box
        className="gallery"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          overflowY: 'auto',
          height: '80vh',
        }}
      >
        <Box sx={{ width: '100%', textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>
          {/* {t('gallery.title')} */}
        </Box>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Typography variant="h5">{t('loading')}</Typography>
          </Box>
        ) : (
          cartData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                width: '400px',
                height: '600px',
                margin: 1,
                opacity: 0,
                animation: 'fadeIn 0.6s forwards',
                animationDelay: `${index * 0.3}s`,
                '&:hover': {
                  cursor: 'pointer',
                  transform: 'scale(1.05)', // Hover animation for scaling
                  transition: 'transform 0.3s ease',
                },
                // Parallax effect based on scroll position
                transform: `translateY(${scrollPosition * 0.1}px)`, // Adjust the multiplier for effect strength
                transition: 'transform 0.1s ease-out',
              }}
            >
              <img
                src={`https://api.asiamehr.com${item.image}`}
                alt={item.title}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '30px',
                  width: '100%',
                  textAlign: 'center',
                  lineHeight: '50px',
                  backgroundColor: 'rgb(189,155,25)',
                  color: 'black',
                  opacity: 0.7,
                  transition: 'all .2s ease-in-out',
                  '&:hover': { opacity: 1 },
                }}
              >
                <Typography variant="h5">{t(`carts.${item._id}.title`, { defaultValue: item.title })}</Typography>
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  opacity: 0,
                  transition: 'opacity 0.5s ease',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': { opacity: 1 },
                }}
              >
                <Typography variant="body1" sx={{ color: 'rgb(189,155,25)', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: 2 }}>
                  {t(`carts.${item._id}.description`, { defaultValue: item.description })}
                </Typography>
                <MuiLink
                  component={Link}
                  to="/project"
                  sx={{
                    color: 'gold',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    padding: '8px 16px',
                    border: '2px solid darkgreen',
                    backgroundColor: '#03452f',
                    borderRadius: '5px',
                    '&:hover': {
                      backgroundColor: 'rgb(193, 161, 34)',
                      color: 'darkgreen',
                      opacity: 1,
                    },
                  }}
                >
                  {t('image.link')}
                </MuiLink>
              </Box>
            </Box>
          ))
        )}
      </Box>

      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(30px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </>
  );
}

export default MainGallery;
