import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carrousel.css';

import { Pagination, Navigation } from 'swiper/modules';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
export default function Carrousel() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [galleryData, setGalleryData] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/gallery');
        const data = await response.json();
        if (response.ok) {
          setGalleryData(data.data.gallery);
          const newTranslations = {};
          data?.data?.gallery?.forEach((cart) => {
            cart.mytext.forEach(({ lang, title, description }) => {
              if (!newTranslations[lang]) newTranslations[lang] = {};
              newTranslations[lang][`gallery.${cart._id}.title`] = title;
              newTranslations[lang][`gallery.${cart._id}.description`] = description
            });
          });
          Object.keys(newTranslations).forEach((lang) => {
            i18next.addResourceBundle(lang, "translation", newTranslations[lang], true);
          });
          console.log("Added Translations:", newTranslations);
          console.log("i18next current resources:", i18next.services.resourceStore.data);
          await i18next.reloadResources();
        } else {
          console.error('Failed to fetch gallery data:', data);
        }
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchGalleryData();
  }, [i18n.language]);

  return (
    <>
     <Helmet>
          <title>{t('companyResponsibility.title')} </title>
          <meta name="description" content={t('companyResponsibility.title')} />
          <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
        </Helmet>
      <div className='title-text'>
        <h1>{t('carousel.title')}</h1>
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}  // Default to showing one slide
        centeredSlides={true}
        spaceBetween={20}
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          780: {
            slidesPerView: 3, // Show three slides on screens larger than 780px
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3, // Keep showing three slides on larger screens
            spaceBetween: 40,
          },
        }}
      >
        {galleryData?.map((cart) => (
          <SwiperSlide key={cart.id}>
            <img src={`https://api.asiamehr.com${cart.image}`} alt={cart.title} />
            <div className='c-text'>
              <h1>{t(`gallery.${cart._id}.title`, { defaultValue: cart.title })}</h1>
              <h3>{cart.description}</h3>
            </div>
          </SwiperSlide>
        ))}


      </Swiper>
    </>
  );
}
