import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Typography, TextField, Button, Container, Grid, Snackbar, Alert } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import Nav from '../../Components/NavBar/Nav';
import { useTranslation } from 'react-i18next';
import Footer from '../../Components/Footer/Footer';

const ContactUs = () => {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState({ from: '', subject: '', text: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handleCloseAlert = () => setAlertOpen(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!emailData.from || !emailData.subject || !emailData.text) {
      setAlertMessage('لطفاً تمامی فیلدها را پر کنید');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    try {
      const response = await fetch('https://api.asiamehr.com/api/v1/contactemail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailData)
      });

      response.status === 200
        ? setAlertMessage('ایمیل با موفقیت ارسال شد.')
        : setAlertMessage('ناموفق');
      setAlertSeverity(response.status === 200 ? 'success' : 'error');
    } catch (err) {
      setAlertMessage('خطا در ارسال اطلاعات');
      setAlertSeverity('error');
    } finally {
      setAlertOpen(true);
    }
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Helmet>
        <title>{t('helmet.contactUs')} </title>
        <meta name="description" content={t('helmet.contactUsDescription')} />
        <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
      </Helmet>
      <Typography
        variant="h1"
        sx={{
          fontSize: '2rem',
          fontWeight: 700,
          marginBottom: '20px',
          textAlign: 'center',
          lineHeight: '1.6',
          color: '#222',
          marginTop: '150px',
          '@media (min-width:600px)': {
            fontSize: '3rem'
          }
        }}
      >
        {t('helmet.contactUs')}
      </Typography>
    
      <Nav />
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5, padding: '0 16px', width: '100%' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>{t('contact.contact_heading')}</Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <form onSubmit={sendEmail}>
              <TextField
                name="subject"
                placeholder={t('contact.name')}
                value={emailData.subject}
                onChange={(e) => setEmailData({ ...emailData, subject: e.target.value })}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  '@media (max-width:600px)': { fontSize: '0.875rem' }
                }}
              />
              <TextField
                name="from"
                placeholder={t('contact.email_label')}
                value={emailData.from}
                onChange={(e) => setEmailData({ ...emailData, from: e.target.value })}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  '@media (max-width:600px)': { fontSize: '0.875rem' }
                }}
              />
              <TextField
                id="message"
                placeholder={t('contact.message_label')}
                multiline
                rows={4}
                value={emailData.text}
                onChange={(e) => setEmailData({ ...emailData, text: e.target.value })}
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                  '@media (max-width:600px)': { fontSize: '0.875rem' }
                }}
              />
              <Button variant="contained" color="primary" type="submit" sx={{ width: '100%' }}>
                {t('contact.send_button')}
              </Button>
            </form>
          </Grid>

          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>

          <Grid item xs={12} sm={6}>
            <iframe
              title={t('contact.map_title')}
              width="100%"
              height="450"
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3229.6604267017588!2d59.334701!3d35.955276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDU3JzE5LjAiTiA1OcKwMjAnMDQuOSJF!5e0!3m2!1sen!2s!4v1712749110672!5m2!1sen!2s"
              style={{
                borderRadius: '4px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                '@media (max-width:600px)': { height: '300px' }
              }}
            ></iframe>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: "center" }}>
            <LocationOnIcon />
            <Typography variant="body1">{t('contact.address')}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CallIcon />
            <Typography variant="body1">{t('contact.office')}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <EmailIcon />
            <Typography variant="body1"> 
              info@asiamehr.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUs;
