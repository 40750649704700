import React from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";



function Main() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "fa" || i18n.language === "ar"; 

  return (
    <>
      <Helmet>
        <title>{t('companyResponsibility.title')} </title>
        <meta name="description" content={t('companyResponsibility.title')} />
        <meta name="keywords" content="contact, get in touch, asia mehr fardad, customer support" />
      </Helmet>
     <Box sx={{ flexGrow: 1, p: 4 }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        direction={isRTL ? "row-reverse" : "row"}
      >
 
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img
               src='/assest/logg.png'
              alt="Asiamehr Fardad || اسیامهر فرداد"
              style={{ width: "100%", maxWidth: 400, borderRadius: 8 }}
            />
          </Box>
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: isRTL ? "right" : "left" }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
              {t("about")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              {t("companyMessage")}
            </Typography>

            <Button
              component={Link}
              to="/about"
              variant="contained"
              sx={{
                backgroundColor: "#FFD700",
                color: "#000",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#E6C200" },
              }}
            >
              {t("button.more")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </>
   
  );
}

export default Main;
