import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Nav from '../../Components/NavBar/Nav';
import GalleryInfo from './galleryInfo'

function Gallery() {
  const { t } = useTranslation();
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/galleryy');
        const data = await response.json();
        if (response.ok) {
          setGalleryItems(data.data); // Adjust according to your API response structure
        } else {
          console.error('Failed to fetch gallery items:', data);
        }
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      }
    };

    fetchGalleryItems();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{t('text.heading')}</title>
        <meta name="description" content={t('text.heading')} />
      </Helmet>
      <Typography variant="h1"  sx={{
          fontSize: '3rem',
          fontWeight: 700,
          marginBottom: '20px',
          textAlign: 'center',  // تراز وسط
          lineHeight: '1.5',     // فاصله خطوط
          color: '#333',   
          marginTop:"150px"
               // رنگ متن
        }}>
        {t('text.heading')}
      </Typography>
  
      <Nav />
      <Container sx={{ textAlign: 'center', paddingY: 4 }}>
      
        <Typography variant="body1" sx={{ marginTop: 2 }}>{t('text.subHeading')}</Typography>
      </Container>
      <GalleryInfo />
    </div>
  );
}

export default Gallery;
