import React, { useContext, useState } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
const EducationWorkInfo = () => {
  const { fields, updateFields } = useContext(FormContext);

  // Initialize work experience and cours fields
  const [workExperienceFields, setWorkExperienceFields] = useState(
    fields.personalInfo?.workExperience || [
      {
        jobTitle: "",
        companyName: "",
        startDate: "",
        endDate: "",
        duration: "",
        reasonForLeaving: "",
        lastSalary: "",
      },
    ]
  );

  const [coursFields, setcoursFields] = useState(
    fields.personalInfo?.cours || [
      {
        coursTitle: "",
        institution: "",
        completionDate: "",
        skillLevel: "",
        duration: "",
        organizer: "",
        date:""
      },
    ]
  );

  const handleFieldChange = (e, index, fieldSetter, fieldState, sectionKey) => {
    const updatedFields = [...fieldState];
    updatedFields[index] = {
      ...updatedFields[index],
      [e.target.name]: e.target.value,
    };

    // Update local state
    fieldSetter(updatedFields);

    // Update context with the entire array
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };

  const handleAddField = (fieldSetter, fieldState, newField) => {
    const updatedFields = [...fieldState, newField];

    // Update local state
    fieldSetter(updatedFields);

    // Update context with the new field
    const sectionKey =
      fieldState === workExperienceFields ? "workExperience" : "cours";
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };

  const handleRemoveField = (index, fieldSetter, fieldState, sectionKey) => {
    const updatedFields = fieldState.filter((_, idx) => idx !== index);

    // Update local state
    fieldSetter(updatedFields);

    // Update context with the removed field
    updateFields("personalInfo", { [sectionKey]: updatedFields });
  };

  return (
    <>   <Box
    sx={{
      direction: "rtl",
      padding: "20px",
      fontFamily: "IRANSans, Arial, sans-serif",
      // backgroundColor: "#f9f9f9",
    }}
  >
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        textAlign: "center",
        marginBottom: "20px",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      اطلاعات وضعیت شغلی
    </Typography>

    <form>
      <Grid container spacing={2}>
        {/* Work Experience Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            سوابق شغلی
          </Typography>
          {workExperienceFields.map((work, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={4}>
                <InputLabel sx={{ marginBottom: "8px" }}>نام شرکت</InputLabel>
                <TextField
                  fullWidth
                  name="companyName"
                  // label="نام شرکت"
                  value={work.companyName}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  عنوان شغلی
                </InputLabel>
                <TextField
                  fullWidth
                  name="jobTitle"
                  // label="عنوان شغلی"
                  value={work.jobTitle}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  مدت زمان همکاری
                </InputLabel>
                <TextField
                  fullWidth
                  name="duration"
                  // label="مدت زمان همکاری"
                  value={work.duration}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  علت ترک کار
                </InputLabel>
                <TextField
                  fullWidth
                  name="reasonForLeaving"
                  // label="reasonForLeaving"
                  value={work.reasonForLeaving}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  آخرین حقوق
                </InputLabel>
                <TextField
                  fullWidth
                  name="lastSalary"
                   placeholder="مبلغ به ریال وارد شود "
                  type="number"
                  value={work.lastSalary}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                />
              </Grid>

              {/* <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  تاریخ شروع
                </InputLabel>
                <TextField
                  fullWidth
                  name="startDate"
                  // label="تاریخ شروع"
                  type="date"
                  value={work.startDate}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  تاریخ پایان
                </InputLabel>
                <TextField
                  fullWidth
                  name="endDate"
                  // label="تاریخ پایان"
                  type="date"
                  value={work.endDate}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setWorkExperienceFields,
                      workExperienceFields,
                      "workExperience"
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Grid> */}
              <Grid item xs={12}>
                {workExperienceFields.length > 1 && (
                  <IconButton
                    onClick={() =>
                      handleRemoveField(
                        index,
                        setWorkExperienceFields,
                        workExperienceFields,
                        "workExperience"
                      )
                    }
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                )}
                {index === workExperienceFields.length - 1 && (
                  <IconButton
                    onClick={() =>
                      handleAddField(
                        setWorkExperienceFields,
                        workExperienceFields,
                        {
                          jobTitle: "",
                          companyName: "",
                          // startDate: "",
                          // endDate: "",
                          duration: "",
                          reasonForLeaving: "",
                          lastSalary: "",
                        }
                      )
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* cours Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            دوره‌های آموزشی
          </Typography>
          {coursFields.map((cours, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}> عنوان</InputLabel>
                <TextField
                  fullWidth
                  name="coursTitle"
                  // label="نام دوره"
                  value={cours.coursTitle}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  مدت دوره{" "}
                </InputLabel>
                <TextField
                  fullWidth
                  name="duration"
                  // label="موسسه آموزشی"
                  value={cours.duration}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  {" "}
                  سطح مهارت
                </InputLabel>
                <TextField
                  fullWidth
                  name="skillLevel"
                  // label="نام دوره"
                  value={cours.skillLevel}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  "موسسه آموزشی"{" "}
                </InputLabel>
                <TextField
                  fullWidth
                  name="organizer"
                  // label="موسسه آموزشی"
                  value={cours.organizer}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  {" "}
                  "تاریخ اتمام"{" "}
                </InputLabel>
                <TextField
                  fullWidth
                  name="completionDate"
                  // label="تاریخ اتمام"
                  // type="date"
                  value={cours.completionDate}
                  onChange={(e) =>
                    handleFieldChange(
                      e,
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                  
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  تاریخ اتمام
                </InputLabel>
                <DatePicker
                  value={cours.date}
                  onChange={(date) =>
                    handleFieldChange(
                      {
                        target: {
                          name: "completionDate",
                          value: date?.format(),
                        },
                      },
                      index,
                      setcoursFields,
                      coursFields,
                      "cours"
                    )
                  }
                  calendar={persian}
                  locale={persian_fa}
                  inputClass="custom-input"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <InputLabel sx={{ marginBottom: "8px" }}>
                  سطح مهارت
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    name="skillLevel"
                    value={cours.skillLevel}
                    onChange={(e) =>
                      handleFieldChange(
                        e,
                        index,
                        setcoursFields,
                        coursFields,
                        "cours"
                      )
                    }
                    sx={{
                      height: "35px", // Adjust the height
                      fontSize: "0.875rem", // Smaller font size
                      padding: "0 14px", // Adjust the padding for smaller height
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          direction: "rtl", // Ensures menu alignment is right-to-left
                          textAlign: "right",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>انتخاب کنید</MenuItem>
                    <MenuItem value="مبتدی">مبتدی </MenuItem>
                    <MenuItem value="متوسط">متوسط</MenuItem>
                    <MenuItem value="پیشرفته">پیشرفته</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {coursFields.length > 1 && (
                  <IconButton
                    onClick={() =>
                      handleRemoveField(
                        index,
                        setcoursFields,
                        coursFields,
                        "cours"
                      )
                    }
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                )}
                {index === coursFields.length - 1 && (
                  <IconButton
                    onClick={() =>
                      handleAddField(setcoursFields, coursFields, {
                        coursTitle: "",
                        institution: "",
                        completionDate: "",
                      })
                    }
                  >
                    <AddCircleIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </form>
  </Box></>
 
  );
};

export default EducationWorkInfo;
