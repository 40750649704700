import React, { useContext, useState } from "react";
import { FormContext } from "../../Context/JobContext";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  Chip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const HealthInfo = () => {
  const { fields, updateFields } = useContext(FormContext);
  const [disabilityStatusFields, setDisabilityStatusFields] = useState([
    fields.personalInfo.disabilityStatus || "",
  ]);

  const handleDisabilityChange = (e, index) => {
    const updatedFields = [...disabilityStatusFields];
    updatedFields[index] = e.target.value;
    setDisabilityStatusFields(updatedFields);
    updateFields("personalInfo", { disabilityStatus: updatedFields });
  };

  const handleAddCondition = () => {
    setDisabilityStatusFields([...disabilityStatusFields, ""]);
  };

  const handleRemoveCondition = (index) => {
    const updatedFields = disabilityStatusFields.filter(
      (_, idx) => idx !== index
    );
    setDisabilityStatusFields(updatedFields);
    updateFields("personalInfo", { disabilityStatus: updatedFields });
  };

  const handleChange = (e) => {
    updateFields("personalInfo", { [e.target.name]: e.target.value });
  };

  return (
    <> <Box
    sx={{
      direction: "rtl",
      padding: "20px",
      fontFamily: "IRANSans, Arial, sans-serif",
      // backgroundColor: "#f9f9f9",
    }}
  >
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        textAlign: "center",
        marginBottom: "20px",
        fontWeight: "bold",
        fontSize: "24px",
      }}
    >
      اطلاعات وضعیت سلامت
    </Typography>
    <form>
      <Grid container spacing={2}>
        {/* Health Status */}
        <Grid item xs={12} sm={6}>
          <InputLabel sx={{ marginBottom: "8px" }}>وضعیت سلامت *</InputLabel>
          <FormControl fullWidth>
            <Select
              name="healthStatus"
              value={fields.personalInfo.healthStatus}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
              variant="outlined"
            >
              <MenuItem value="" disabled>
                انتخاب کنید
              </MenuItem>
              <MenuItem value="بیمار">بیمار</MenuItem>
              <MenuItem value="سالم">سالم</MenuItem>
              <MenuItem value="دیگر">دیگر</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel sx={{ marginBottom: "8px" }}>سابقه جراحی *</InputLabel>
          <FormControl fullWidth>
            <Select
              name="surgeryHistory"
              value={fields.personalInfo.surgeryHistory}
              onChange={handleChange}
              sx={{
                height: "35px", // Adjust the height
                fontSize: "0.875rem", // Smaller font size
                padding: "0 14px", // Adjust the padding for smaller height
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    direction: "rtl", // Ensures menu alignment is right-to-left
                    textAlign: "right",
                  },
                },
              }}
              variant="outlined"
            >
              <MenuItem value="دیگر">بدون جراحی </MenuItem>
              <MenuItem value="جراحی نیاز است">جراحی نیاز است</MenuItem>
              <MenuItem value="جراحی انجام شده">جراحی انجام شده</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Disability Status */}
        {disabilityStatusFields.map((condition, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <InputLabel sx={{ marginBottom: "8px" }}>
              وضعیت بیماری {index + 1}
            </InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  name={`disabilityStatus${index}`}
                  variant="outlined"
                  value={condition}
                  onChange={(e) => handleDisabilityChange(e, index)}
                  placeholder="وضعیت بیماری"
                />
              </Grid>
              <Grid item xs={3}>
                {disabilityStatusFields.length > 1 && (
                  <IconButton onClick={() => handleRemoveCondition(index)}>
                    <RemoveCircleIcon />
                  </IconButton>
                )}
                {index === disabilityStatusFields.length - 1 && (
                  <IconButton onClick={handleAddCondition}>
                    <AddCircleIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}

        {/* Surgery History */}
      </Grid>
    </form>
  </Box></>
   
  );
};

export default HealthInfo;
