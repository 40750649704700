import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

const FlagIcon = styled('img')({
  width: 24,
  height: 16,
  marginRight: 8,
});

const TranslateButton = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [textDirection, setTextDirection] = useState(i18n.language === 'fa' || i18n.language === 'ar' ? 'rtl' : 'ltr');

  useEffect(() => {
    setTextDirection(i18n.language === 'fa' || i18n.language === 'ar' ? 'rtl' : 'ltr');
  }, [i18n.language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (language) => {
    if (language) {
      i18n.changeLanguage(language);
    }
    setAnchorEl(null);
  };

  return (
    <div style={{ direction: textDirection, textAlign: i18n.language === 'en' ? 'left' : 'right' }}>
     <IconButton  onClick={handleClick} color="inherit" style={{ color: '#c1a122' }}>
        <LanguageIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose(null)}>
      

        <MenuItem onClick={() => handleClose('fa')}>
          <ListItemIcon>
            <FlagIcon src="https://flagcdn.com/w40/ir.png" alt="Persian" />
          </ListItemIcon>
          <ListItemText primary="فارسی" />
        </MenuItem>
        <MenuItem onClick={() => handleClose('ar')}>
          <ListItemIcon>
            <FlagIcon src="https://flagcdn.com/w40/sa.png" alt="Arabic" />
          </ListItemIcon>
          <ListItemText primary="عربي" />
        </MenuItem>
        <MenuItem onClick={() => handleClose('en')}>
          <ListItemIcon>
            <FlagIcon src="https://flagcdn.com/w40/gb.png" alt="English" />
          </ListItemIcon>
          <ListItemText primary="English" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TranslateButton;
