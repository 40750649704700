import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  Button,
  Box,
  useMediaQuery,

} from "@mui/material";
import { Link } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const JobCards = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); 
  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await fetch("https://api.asiamehr.com/api/v1/job");
        if (!response.ok) {
          throw new Error("Failed to fetch job data");
        }
        const data = await response.json();
        console.log(data);
        setJobData(data.data); 
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };
  window.scrollTo(0,0)
    fetchJobData();
  }, []);

  return (
    <>
       <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: isMobile ? "column" : "row",
        minHeight: "100vh",
        width: "100%",
        padding: 2, 
        backgroundColor: "#f5f5f5",
        direction: "rtl",
        gap: "20px", // Space between cards
        flexWrap: "wrap", // Ensure wrapping for small screens
      }}
    >
      {jobData.map((job, index) => (
        <Card
          key={index}
          sx={{
            width: 370, // Set a fixed width for all cards
            height: 700, // Set a fixed height for all cards
            padding: 2,
            borderRadius: 4,
            boxShadow: 3,
            backgroundColor: "lightGray",
            display: "flex", // Ensure consistent layout
            flexDirection: "column", // Stack content vertically
            justifyContent: "space-between", // Ensure even distribution of content
          }}
        >
          <Box sx={{ textAlign: "center", marginBottom: 2 }}>
            {/* Displaying the logo image */}
            {job.logo ? (
              <img
                src={`https://api.asiamehr.com${job.logo}`} // Assuming logo is stored in /uploads/ and sent from the backend
                alt={job.title}
                style={{
                  width: "80px", // Adjust width as needed
                  height: "80px", // Adjust height as needed
                  objectFit: "cover", 

                  marginBottom: "16px", 
                }}
              />
            ) : (
              <AccountBalanceIcon
                sx={{
                  fontSize: 48,
                  color: "#000",
                }}
              />
            )}
          </Box>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", marginBottom: "16px" }}
          >
            {job.title} {/* Dynamically render job title */}
          </Typography>
          <CardContent sx={{ flex: 1 }}>
            <Typography variant="body1" gutterBottom>
              <strong>تحصیلات:</strong> {job.education}{" "}
              {/* Dynamically render education */}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>مهارت ها:</strong>
            </Typography>
            <List>
              {Array.isArray(job.skills) && job.skills.length > 0 ? (
                job.skills.map((skill, idx) => (
                  <ListItem key={idx}  style={{ textAlign: 'right', direction: 'rtl', whiteSpace: 'normal' }}>
                    - {skill.text}
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  هیچ مهارتی برای این شغل ثبت نشده است.
                </Typography>
              )}
            </List>

            <Typography variant="body1">
              <strong>جنسیت:</strong> {job.gender}{" "}
              {/* Dynamically render gender */}
            </Typography>
            <Typography variant="body1">
              <strong>توضیحات :</strong> {job.description}{" "}
              {/* Dynamically render gender */}
            </Typography>
          </CardContent>

          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <Button
              component={Link}
              to="/apply"
              variant="contained"
              sx={{
                backgroundColor: "#D3A756",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#b38d45",
                },
                borderRadius: 4,
              }}
            >
              تکمیل فرم
            </Button>
          </Box>
        </Card>
      ))}
    </Box>
    </>
 
  );
};

export default JobCards;
