import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';
import en from './Translate/En/default.json'
import fa from './Translate/fa/default.json'
import ar from './Translate/Ar/default.json'
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from './utils/AuthProvider';
import { LanguageProvider } from './utils/LanguageContext';
import { ThemeContextProvider } from './utils/ThemeContext';
import { FormProvider } from './Context/JobContext';
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'fa',  
  resources: {
    fa: { translation: fa },
    en: { translation: en },
    ar:{translation: ar}
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
  <React.StrictMode>
  <LanguageProvider>
  <I18nextProvider i18n={i18next}>
  <ThemeContextProvider>
    <FormProvider>
  <App />
  </FormProvider>
  </ThemeContextProvider>

</I18nextProvider>
</LanguageProvider>
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
